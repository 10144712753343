<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="分部分项" prop="wbsPnames">
            <el-input v-model="dataForm.wbsPnames" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际开工日期" prop="wbsStartDate">
            <el-date-picker style="width: 100%" v-model="dataForm.wbsStartDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择实际开工日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际完工日期" prop="wbsEndDate">
            <el-date-picker style="width: 100%" v-model="dataForm.wbsEndDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择实际完工日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="累计完成比例" prop="percentage">
            <el-input-number v-model="dataForm.percentage" :min="0" :max="1" :data-unit="dataForm.percentage" placeholder="累计完工百分比，请按小数填写" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上报日期" prop="reportDate">
            <el-date-picker style="width: 100%" v-model="dataForm.reportDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择上报日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        wbsId:'',
        contractId: '',
        wbsName: '',
        wbsPnames: '',
        percentage: null,
        reportDate: '',
        wbsStartDate: '',
        wbsEndDate: '',
      },
      dateFormat: 'yyyy-MM-dd'
    }
  },
  computed: {
    dataRule () {
      return {
        reportDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        percentage: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/scheduleProgress/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if(this.dataForm.wbsPnames){
          this.dataForm.wbsPnames=this.dataForm.wbsPnames+','+ this.dataForm.wbsName
        }else{
          this.dataForm.wbsPnames=this.dataForm.wbsName
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '正在执行更新操作...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/scheduleProgress/', this.dataForm).then(({ data: res }) => {
          loading.close()
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          loading.close()
        })
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
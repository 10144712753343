<template>
  <el-dialog top="5vh" width="98%" :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="12">
        <vxe-toolbar>
          <template #buttons>
            <vxe-input v-model="filterName" type="search" placeholder="搜索" clearable @keyup.enter.native="searchEvent" @search-click="searchEvent" @clear="searchEvent"></vxe-input>
          </template>
        </vxe-toolbar>
        <vxe-table
            ref="tree"
            v-show="filterName === ''"
            resizable
            :loading="treeLoading"
            show-overflow
            :height="maxHeight"
            @checkbox-change="treeCheckboxChange"
            :tooltip-config="{theme: 'light'}"
            :row-config="{isCurrent: true, isHover: true, useKey: true, height: 27, keyField: 'id'}"
            :tree-config="{lazy: true, showLine: true, iconOpen: 'vxe-icon-caret-right rotate45', iconClose: 'vxe-icon-caret-right', hasChildField: 'isNotSubBoq', loadMethod: loadChildrenMethod}"
            :checkbox-config="{labelField: 'name', visibleMethod: checkboxVisibleMethod, checkMethod: checkboxCheckMethod, highlight: true, showHeader: false, checkStrictly: true, checkField: 'checked'}"
            :data="treeData"
            @cell-click="treeCellClick"
            :scroll-y="{enabled: true}">
          <vxe-column type="checkbox" title="分项清单" min-width="300" tree-node>
            <template v-slot="{ row }">
              <span :style="row.measNum === 0 ? {color: 'lightgrey'} : {}">{{ (row.code ? `${row.code} ${row.name}` : `${row.name}`) }}</span>
<!--              <span style="color: #003399" v-if="row.subBoqCount !== undefined">（{{ row.subBoqCount }}）</span>-->
            </template>
          </vxe-column>
          <vxe-column field="unitName" title="单位" width="80" align="center"></vxe-column>
          <vxe-column field="num" title="合同数量" width="120" align="right"></vxe-column>
          <vxe-column field="measNum" title="可计量数量" width="120" align="right"></vxe-column>
          <vxe-column field="typeId" title="清单类型" width="90" align="center">
            <template v-slot="{ row }">
              <span v-if="row.typeId === 1" style="color: green">原始清单</span>
              <span v-else-if="row.typeId === 2 || row.typeId === 3" style="color: #F95738">变更清单</span>
              <span v-else-if="row.typeId === 4" style="color: #B22222">暂定清单</span>
              <span v-else style="color: #556B2F"></span>
            </template>
          </vxe-column>
          <vxe-column field="changeOrderCode" title="变更令编号" width="120"></vxe-column>
        </vxe-table>
        <vxe-table
            ref="filterTable"
            v-show="filterName !== ''"
            resizable
            :loading="filterTableLoading"
            :height="maxHeight"
            @checkbox-change="treeCheckboxChange"
            :tooltip-config="{theme: 'light'}"
            :row-config="{isCurrent: true, isHover: true, useKey: true, keyField: 'id'}"
            :checkbox-config="{labelField: 'name', checkMethod: checkboxCheckMethod, highlight: true, showHeader: false, checkStrictly: true, checkField: 'checked', trigger:'row'}"
            :data="filterTableData"
            :scroll-y="{enabled: false}">
          <vxe-column type="checkbox" title="工程/费用名称" min-width="200" tree-node>
            <template v-slot="{ row }">
              <span style="white-space: normal;">{{ `${row.pnames}/${row.subItemName}` }}</span>
            </template>
          </vxe-column>
          <vxe-column field="boqName" title="清单" width="170">
            <template v-slot="{ row }">
              <span style="white-space: normal;">{{ `${row.boqName}(${row.boqCode})` }}</span>
            </template>
          </vxe-column>
          <vxe-column field="num" title="合同数量" width="120" align="right"></vxe-column>
          <vxe-column field="measNum" title="可计量数量" width="120" align="right"></vxe-column>
          <vxe-column field="typeId" title="清单类型" width="90" align="center">
            <template v-slot="{ row }">
              <span v-if="row.typeId === 1" style="color: green">原始清单</span>
              <span v-else-if="row.typeId === 2 || row.typeId === 3" style="color: #F95738">变更清单</span>
              <span v-else-if="row.typeId === 4" style="color: #B22222">暂定清单</span>
              <span v-else style="color: #556B2F">未知类型</span>
            </template>
          </vxe-column>
          <vxe-column field="changeOrderCode" title="变更令编号" width="150"></vxe-column>
        </vxe-table>
      </el-col>
      <el-col :span="12">
        <div style="height: 42px"></div>
        <vxe-table
            ref="checkedTable"
            resizable
            :height="maxHeight"
            show-overflow="tooltip"
            :expand-config="{accordion: true, trigger: 'row'}"
            :row-config="{isCurrent: true, isHover: true, useKey: true, keyField: 'id'}"
            :edit-config="{trigger: 'click'}"
            :edit-rules="editRules"
            :tooltip-config="{theme: 'light'}"
            :data="checkedRows">
          <vxe-column width="30" align="center" :show-overflow="false">
            <template v-slot="{ row }">
              <i class="el-icon-remove" style="color:red;cursor:pointer;" @click.stop="deleteCheckedRow(row)"></i>
            </template>
          </vxe-column>
          <vxe-column type="expand" title="工程/费用名称" min-width="200">
            <template v-slot="{ row }">
              <span>{{ `${row.subItemName}` }}</span>
            </template>
            <template #content="{ row }">
              <vxe-form
                  style="width: 700px; margin: 0 10px;"
                  title-colon
                  title-align="right"
                  title-width="80"
                  :rules="{name: [{ required: true, message: '请填写计量部位' }]}"
              >
<!--                <vxe-form-item v-if="measTicketMap.get(row.subItemId).wbsId != measTicketMap.get(row.subItemId).subItemId" title="分部分项" span="24">-->
<!--                  <template>-->
<!--                    <wbs-select v-model="measTicketMap.get(row.subItemId).wbsId" :contractId="dataForm.contractId" :subcontractor-id="dataForm.subcontractorId" placeholder="请选择分部分项" @change="wbsSelectChangeHandle($event, row)"></wbs-select>-->
<!--                  </template>-->
<!--                </vxe-form-item>-->

                <vxe-form-item title="计量部位" field="name" span="12">
                  <template>
                    <vxe-input v-model="measTicketMap.get(row.subItemId).name" placeholder="请填写计量部位" clearable></vxe-input>
                  </template>
                </vxe-form-item>
                <vxe-form-item title="起讫桩号" span="12">
                  <template>
                    <vxe-input v-model="measTicketMap.get(row.subItemId).stakeNo" placeholder="请填写起讫桩号" clearable></vxe-input>
                  </template>
                </vxe-form-item>
                <vxe-form-item title="交工证书" span="12">
                  <template>
                    <vxe-input v-model="measTicketMap.get(row.subItemId).ctoa" placeholder="请填写交工证书号" clearable></vxe-input>
                  </template>
                </vxe-form-item>

                <vxe-form-item title="图号" span="12">
                  <template>
                    <vxe-input v-model="measTicketMap.get(row.subItemId).drawingNo" placeholder="请填写图号" clearable></vxe-input>
                  </template>
                </vxe-form-item>
                <vxe-form-item title="计算式" span="24">
                  <template>
                    <vxe-textarea v-model="measTicketMap.get(row.subItemId).formula" placeholder="请填写计算式" clearable></vxe-textarea>
                  </template>
                </vxe-form-item>
              </vxe-form>
            </template>
          </vxe-column>
          <vxe-column field="boqName" title="清单" width="200">
            <template v-slot="{ row }">
              <span>{{ `${row.boqCode} ${row.boqName}` }}</span>
            </template>
          </vxe-column>
          <vxe-column field="unitName" title="单位" width="80" align="center"></vxe-column>
          <vxe-column class-name="cell-underline" field="measNum" title="申请数量" width="150" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
          <vxe-column field="typeId" title="清单类型" width="90" align="center">
            <template v-slot="{ row }">
              <span v-if="row.typeId === 1" style="color: green">原始清单</span>
              <span v-else-if="row.typeId === 2 || row.typeId === 3" style="color: #F95738">变更清单</span>
              <span v-else-if="row.typeId === 4" style="color: #B22222">暂定清单</span>
              <span v-else style="color: #556B2F">未知类型</span>
            </template>
          </vxe-column>
          <vxe-column field="changeOrderCode" title="变更令编号" width="150"></vxe-column>
          <vxe-column title="名称路径" min-width="1000">
            <template v-slot="{ row }">
              <span>{{ `${row.pnames}/${row.subItemName}` }}</span>
            </template>
          </vxe-column>
        </vxe-table>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>
<!-- 计量申请时批量添加分项清单 -->
<script>
import debounce from 'lodash/debounce'
import XEUtils from "xe-utils";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        subcontractorId: '',
        periodId: '',
        measId: '',
      },
      // 储存
      measTicketMap: new Map(),
      treeLoading: true,
      filterTableLoading: false,
      existIds: [],
      title: '计量申请',
      newMeas: false,
      treeData: [],
      filterTableData: [],
      checkedRows: [],
      filterName: '',
      maxHeight: 400,
      // 是否允许超计量（0：否，1：是）
      allowExceed: 0,
      editRules: {
        measNum: [
          {required: true, type: 'number', message: '请填写数量', trigger: 'manual'}
        ],
      },
    }
  },
  computed: {
    dataRule() {
      return {}
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 225
  },
  methods: {
    init() {
      this.visible = true
      this.getSubItemChildren({id: 0}).then((data) => {
        this.treeData = data
      })
      this.getExistList()
    },
    // 如果展开部位非叶节点，则加载下级部位
    // 如果展开部位叶节点, 则加载该清单已分解的零号清单
    loadChildrenMethod({ row }) {
      if (row.isLeaf == 1) {
        return this.getSubBoq(row)
      } else {
        return this.getSubItemChildren(row)
      }
    },
    getSubItemChildren(row) {
      return new Promise((resolve, reject) => {
        let pid = row.id
        this.$http.get(
            '/mps/measTicket/subItemChildrenForBatchAdd',
            {
              params: {
                'contractId': this.dataForm.contractId,
                'subcontractorId': this.dataForm.subcontractorId,
                'pid': pid,
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
            reject()
          }
          if (res.data) {
            for (let obj of res.data) {
              obj.isNotSubBoq = true
            }
          }
          resolve(res.data)
        }).catch(() => {
          this.$message.error('加载出错')
          reject()
        })
      })
    },
    getSubBoq(row) {
      return new Promise((resolve, reject) => {
        let pid = row.id
        this.$http.get(
            '/mps/measTicket/subBoqListWithNum',
            {
              params: {
                'periodId': this.dataForm.periodId,
                'subItemId': pid,
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
            reject()
          }
          if (res.data) {
            for (let obj of res.data) {
              obj.isNotSubBoq = false
            }
            if (res.data.length > 0) {
              for (let obj of res.data) {
                obj.code = obj.boqCode
                obj.name = obj.boqName
                // 如果此分项为实体工程，且wbsId为空，则不允许计量（将measNum置为0）
                if (obj.isEntity && !obj.wbsId) {
                  obj.measNum = 0
                  continue
                }
                // 如果此分项有计量前置条件（即precondition > 0）,则可计量量取preconditionAmount/boqPrice,保留4位小数
                if (obj.precondition > 0) {
                  obj.measNum = XEUtils.floor(XEUtils.divide(obj.preconditionAmount, obj.boqPrice), 4)
                  continue
                }
                // 根据结果中的合同工程量和累计到上期末的已计量数量,以及当前可计量的百分比，计算出剩余需要计量的数量
                obj.leftNum = XEUtils.subtract(XEUtils.multiply(obj.num,obj.percentage), obj.measSum)
                if (obj.leftNum > 0) {
                  obj.measNum = obj.leftNum
                } else {
                  obj.measNum = 0
                }
              }
            } else {
            // 如果查询到的零号清单列表数据量为0，则将此subitem节点设为不能展开，避免用户误解
              row.isNotSubBoq = false
            }
          }
          resolve(res.data)
        }).catch(() => {
          this.$message.error('加载出错')
          reject()
        })
      })
    },
    // 查询已存在的计量单对应的零号清单id
    getExistList() {
      // 若measId为空，则不需要查询
      if (!this.dataForm.measId) {
        this.treeLoading = false
      } else {
        // 查询已计量的零号清单id
        this.$http.get(`/mps/measMx/existSubBoqIdListByMeasId/${this.dataForm.measId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.existIds = res.data
          this.treeLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    // 左侧树节点的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        this.$refs.tree.setTreeExpand(row, !this.$refs.tree.isTreeExpandByRow(row))
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(async function () {
      if (this.checkedRows.length == 0) {
        return this.$message.error("请至少选择一条清单进行计量")
      }
      this.$refs.checkedTable.validate(true).then(async (ErrMap) => {
        if (!ErrMap) {
          // 先校验是否超计量
          if (this.allowExceed !== 1) {
            // 如果不允许超计量,且填写的计量数量已超计量，则提醒
            for (let i = 0; i < this.checkedRows.length; i++) {
              let row = this.checkedRows[i]
              // 如果此分项为实体工程
              // todo 如果之后确认了，此页面可以修改sbs和wbs的绑定关系，则为了保证此处的校验成立，必须在修改绑定关系的同时，也同步更新这一行数据的wbsid
              if (row.isEntity) {
                // 1.若wbsId为空，则不允许计量
                // 2.若质检资料状态为0，也不允许计量
                if (!row.wbsId) {
                  this.$alert(`第${XEUtils.add(i, 1)}行分项清单未关联分部分项工程，不允许计量！`, '操作出错', {
                    confirmButtonText: '关闭',
                  })
                  return
                } else if (row.qaFileState === 0) {
                  this.$alert(`第${XEUtils.add(i, 1)}行分项清单未上传质检资料，不允许计量！`, '操作出错', {
                    confirmButtonText: '关闭',
                  })
                  return
                }
              }
              if (row.measNum > row.leftNum) {
                this.$alert(`第${XEUtils.add(i, 1)}行${row.boqCode} ${row.boqName}剩余可计量数量是${row.leftNum}，您申请的数量已超出限制！`, '操作出错', {
                  confirmButtonText: '关闭',
                })
                return
              }
              if (row.measNum == 0) {
                this.$alert(`第${XEUtils.add(i, 1)}行清单的[计量数量]为0，请重新填写！`, '操作出错', {
                  confirmButtonText: '关闭',
                })
                return
              }
            }
          }
          // 校验计量单的计量部位是否为空
          for (let i = 0; i < this.checkedRows.length; i++) {
            let row = this.checkedRows[i]
            let measTicket = this.measTicketMap.get(row.subItemId)
            if (measTicket.name == null || measTicket.name == '') {
              this.$alert(`第${XEUtils.add(i, 1)}行的[计量部位]未填写！`, '操作出错', {
                confirmButtonText: '关闭',
              })
              return
            }
          }
          let newData = []
          // 将subItemId和boqId传至后台，避免了后台还需要通过id去查询
          for (let row of this.checkedRows) {
            newData.push({
              subItemId: row.subItemId,
              boqId: row.boqId,
              num: row.measNum
            })
          }
          let measTicketList = []
          this.measTicketMap.forEach((value, key) => {
            measTicketList.push(value)
          })
          let params = {
            periodId: this.dataForm.periodId,
            measId: this.dataForm.measId,
            newData: newData,
            measTicketList: measTicketList
          }
          this.$http.post(
              '/mps/measTicket/batchAdd',
              params
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
                if (newData.length > 0) {
                  this.$emit('refreshDataList', newData[0].subItemId)
                } else {
                  this.$emit('refreshDataList')
                }
              }
            })
          }).catch(() => {
          })
        }
      }).finally(() => {
      })
    }, 1000, {'leading': true, 'trailing': false}),
    checkboxVisibleMethod({row}) {
      return !row.isNotSubBoq && row.boqId
    },
    checkboxCheckMethod({row}) {
      return !this.existIds.includes(row.id)
    },
    // 关键字搜索
    searchEvent() {
      if (this.filterName !== '') {
        this.filterTableLoading = true
        // 按关键字查询subBoq 关联的 工程名称、清单编号、清单名称
        this.$http.get(
            `/mps/measTicket/searchSubBoq`,
            {
              params: {
                'contractId': this.dataForm.contractId,
                'subcontractorId': this.dataForm.subcontractorId,
                'periodId': this.dataForm.periodId,
                'keyword': this.filterName,
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          for (let obj of res.data) {
            // 如果此分项为实体工程，且wbsId为空，则不允许计量（将measNum置为0）
            if (obj.isEntity && !obj.wbsId) {
              obj.measNum = 0
              continue
            }
            // 根据结果中的合同工程量和累计到上期末的已计量数量，以及当前可计量的百分比，计算出剩余需要计量的数量
            obj.leftNum = XEUtils.subtract(XEUtils.multiply(obj.num,obj.percentage), obj.measSum)
            if (obj.leftNum > 0) {
              obj.measNum = obj.leftNum
            } else {
              obj.measNum = 0
            }
          }
          this.filterTableData = res.data
        }).catch(() => {
          this.filterTableData = []
          return this.$message.error('网络错误，请重试')
        }).finally(() => {
          this.filterTableLoading = false
        })
      } else {
        this.filterTableData = []
      }
    },
    // 树的checkbox 的change事件处理方法
    treeCheckboxChange({checked, row}) {
      let id = row.id
      if (checked) {
        // 勾选
        // 如果checkedRows中不包含当前的行，才进行处理（因为有多种途径（树表格，或者搜索表格）往checkedRows中添加行）
        if (!this.checkedRows.some((checkedRow) => checkedRow.id == id)) {
          this.checkedRows.push(row)
          // 判断measTicketMap中是否包含此行对应的计量单（用于右侧表格展开行）
          // 如果不包含，则将对应的subItem加入map中
          if (!this.measTicketMap.has(row.subItemId)) {
            this.measTicketMap.set(row.subItemId, {
              subItemId: row.subItemId,
              wbsId: row.wbsId,
              name: row.subItemName
            })
            this.$http.get(`/mps/measTicket/infoByMeasIdAndSubItemId/${this.dataForm.measId}/${row.subItemId}`).then(({data: res}) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              }
              if (res.data != null) {
                this.measTicketMap.set(row.subItemId, res.data)
              }
              // 如果这是右边表格的第一行，则自动展开这一行的计量单信息
              if (this.checkedRows.length == 1) {
                this.$refs.checkedTable.setRowExpand(this.checkedRows[0], true)
              }
            }).catch(() => {
              return this.$message.error('出错了')
            })
          } else {
            // 如果这是右边表格的第一行，则自动展开这一行的计量单信息
            if (this.checkedRows.length == 1) {
              this.$refs.checkedTable.setRowExpand(this.checkedRows[0], true)
            }
          }
        }
      } else {
        // 取消勾选
        this.checkedRows = this.checkedRows.filter((checkedRow) => checkedRow.id != id)
        // 如果将对应行从checkedRows中删除后，checkedRows中不再存在与被删除行的subItemId相同的行，则将此subItemId对应的entry从measTicketMap中删除
        if (!this.checkedRows.some(checkedRow => checkedRow.subItemId == row.subItemId)) {
          this.measTicketMap.delete(row.subItemId)
        }
      }
    },
    // 删除选中的行
    deleteCheckedRow(row) {
      this.checkedRows = this.checkedRows.filter((checkedRow) => checkedRow.id != row.id)
      // 如果将对应行从checkedRows中删除后，checkedRows中不再存在与被删除行的subItemId相同的行，则将此subItemId对应的entry从measTicketMap中删除
      if (!this.checkedRows.some(checkedRow => checkedRow.subItemId == row.subItemId)) {
        this.measTicketMap.delete(row.subItemId)
      }
    },
    numFormatter({cellValue, row}) {
      if (XEUtils.isNumber(row.accuracy)) {
        return XEUtils.commafy(cellValue,{digits: row.accuracy})
      } else {
        return cellValue
      }
    },
    // wbs选择组件的change事件
    wbsSelectChangeHandle(e, row) {
      // 如果选择了wbs且计量单的“部位”字段为空，则将wbs的name赋值给“部位”字段
      let measTicket = this.measTicketMap.get(row.subItemId)
      if (!measTicket.name) {
        measTicket.name = e.name
        // 手动触发一次展开行的关闭和展开，以刷新展开行内的表单数据（因为数据的绑定对象层级较深，无法触发vue自动的双向绑定机制）
        this.$refs.checkedTable.toggleRowExpand(row)
        this.$nextTick(() => {
          this.$refs.checkedTable.toggleRowExpand(row)
        })
      }
    },
  }
}
</script>

<template>
  <div>
    <el-dialog v-dialog-drag width="80%" :visible.sync="visible" :title="$t('add')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <el-button v-if="$hasPermission('opm:scheduleprogress:save')" type="primary" size="mini" @click="openWbsSelectHandle()">选择分部分项工程</el-button>
      <vxe-table id="table"
                 keep-source
                 ref="tableRef"
                 :loading="dataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataList"
                 :max-height="tableHeight"
                 :edit-rules="tableRules"
                 :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
        <vxe-column field="wbsPnames" title="分部分项" min-width="300" fixed="left"></vxe-column>
        <vxe-column field="wbsUnitName" title="单位" width="100" align="center" fixed="left"></vxe-column>
        <vxe-column field="percentage" title="累计完成比例" width="120" fixed="left" align="right"
                    :edit-render="{name: '$input', props: {type: 'number', min: 0, max: 1, controls: false}}"></vxe-column>
        <vxe-column field="wbsStartDate" title="实际开工日期" width="120" align="center" :edit-render="{name: '$input', props: {type: 'date'}}"></vxe-column>
        <vxe-column field="wbsEndDate" title="实际完工日期" width="120" align="center" :edit-render="{name: '$input', props: {type: 'date'}}"></vxe-column>
        <vxe-column field="reportDate" title="上报日期" width="120" align="center"  :edit-render="{name: '$input', props: {type: 'date'}}"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <el-button type="text" size="small" @click="deleteOneHandle(row)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <!-- wbs选择弹窗 -->
    <el-dialog top="10vh" :visible.sync="wbsVisible" title="分部分项工程" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-tree
        class="filter-tree"
        v-loading="treeLoading"
        node-key="id"
        :props="wbsTreeProps"
        ref="wbsTree"
        lazy
        show-checkbox
        :load="load"
        highlight-current
        :default-expanded-keys="expandedKeys"
      >
      </el-tree>
      <template slot="footer">
        <el-button @click="wbsVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="wbsSelectSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        wbsId: '',
        percentage: '',
        reportDate: ''
      },
      tableHeight: 400,
      dataList: [],
      // 行校验
      tableRules: {
        percentage: [
          {required: true,type: 'string',message: '请填写累计完工百分比'}
        ],
        reportDate: [
          {required: true,type: 'string',message: '请选择上报日期'}
        ],
      },
      dataListLoading: false,

      // wbs弹窗
      wbsVisible: false,
      wbsTreeProps: {
        label: 'name',
        isLeaf: 'isLeaf',
        disabled: this.checkBoxDisabled
      },
      treeLoading: false,
      expandedKeys: []
    }
  },
  computed: {
    dataRule () {
      return {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  methods: {
    init () {
      this.visible = true
    },

    load(node, resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get(
        '/opm/scheduleProgress/wbs/children',
        {
          params: {
            'pid': pid,
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }

        resolve(res.data)

        // 实现默认展开第一级节点
        if((!node || !node.parent) && res.data.length > 0) {
          this.expandedKeys = [res.data[0].id]
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },

    // 表单提交
    dataFormSubmitHandle: debounce(async function () {
      let rs = await this.dataRowValidate('tableRef')
      if (!rs) {
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在保存...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http['put']('/opm/scheduleProgress/batchSaveOrUpdate',{saveOrUpdates: this.$refs.tableRef.getTableData().fullData ||[]}).then(({data: res}) => {
        loading.close()
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
        loading.close();
      })
    },1000,{'leading': true,'trailing': false}),

    // 数据行校验。验证不通过，返回false
    async dataRowValidate (vxeTableRef) {
      let $tableRef = this.$refs[vxeTableRef]
      let errMap = await $tableRef.validate(true).catch((errMap) => errMap)
      if (errMap) {
        const {row,rule} = Object.values(errMap)[0][0] // 取第一个错误信息
        const rowIndex = $tableRef.getVTRowIndex(row)
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: '第' + (rowIndex + 1) + '行 校验错误：' + rule.$options.message + '',
          duration: 2000
        });
        return false
      }
      return true
    },
    deleteOneHandle (row) {
      let wbsId = row.wbsId
      this.$refs.tableRef.remove(row)
      // 设置wbs取消勾选
      this.$refs.wbsTree.setChecked(wbsId,false)
      this.dataList = this.$refs.tableRef.getTableData().fullData
    },
    // 打开wbs选择弹窗
    openWbsSelectHandle () {
      this.wbsVisible = true
    },

    //  wbs选择弹窗,确定按钮点击事件
    wbsSelectSubmitHandle: debounce(function () {
      let selectedWbsIdMap = new Map()
      if (this.dataList.length > 0) {
        for (let i = 0; i < this.dataList.length; i++) {
          let data = this.dataList[i];
          selectedWbsIdMap.set(data['wbsId'],data)
        }
      }

      let nodes = this.$refs.wbsTree.getCheckedNodes(true)
      let newDataList = []
      let nowDate = this.getCurrentDateStr()
      if (nodes && nodes.length > 0) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          if (selectedWbsIdMap.has(node.id)) {
            newDataList.push(selectedWbsIdMap.get(node.id))
          } else {
            let data = {
              id: '',
              contractId: this.dataForm.contractId,
              wbsId: node.id,
              percentage: 1,// 默认完成比例是 100%
              reportDate: nowDate,
              wbsPnames: node.pnames?node.pnames+'/'+node.name:node.name,
              wbsUnitName: node.unitName || '',
              wbsStartDate: node.startDate || '',
              wbsEndDate: node.endDate || ''
            }
            newDataList.push(data)
          }
        }
      }
      this.dataList = newDataList
      this.wbsVisible = false
    }, 1000, { 'leading': true, 'trailing': false }),

    checkBoxDisabled (data) {
      return !data.isLeaf
    },

    // 获取当前时间字符串
    getCurrentDateStr () {
      var currentDate = new Date();
      var year = currentDate.getFullYear().toString();
      var month = (currentDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = "0" + month;
      }
      var date = currentDate.getDate().toString();
      if (date.length === 1) {
        date = "0" + date;
      }
      return year + "-" + month + "-" + date
    },

  }
}
</script>
<style lang="scss" scope>
  .filter-tree {
    max-height: calc(90vh - 220px);
    overflow: auto;
  }
</style>
<template>
  <el-dialog width="60%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="会议名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="会议名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会议日期" prop="meetingDate">
            <el-date-picker type="date" placeholder="会议日期" value-format="yyyy-MM-dd" v-model="dataForm.meetingDate" :clearable="false" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会议地点" prop="address">
            <el-input v-model="dataForm.address" placeholder="会议地点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主持人" prop="hostName">
            <el-input v-model="dataForm.hostName" placeholder="主持人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="记录人" prop="recorder">
            <el-input v-model="dataForm.recorder" placeholder="记录人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="主要内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        name: '',
        meetingDate: '',
        address: '',
        hostName: '',
        recorder: '',
        contents: ''
      }
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        meetingDate: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        address: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/safetyMeeting/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/safetyMeeting/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
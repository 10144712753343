<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__estmade">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select ref="projectSelect" placeholder="项目选择" v-model="dataForm.prjId" :defaultSelect="true" @change="projectChange"></project-select>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="import" @click="importMadeHandle" v-if="(locked != 1) && $hasPermission('mps:estMade:import')"></toolbar-button>
            <toolbar-button role="lock" @click="lock()" v-if="locked != 1" :disabled="!$hasPermission('mps:estMade:lock-budget')"></toolbar-button>
            <toolbar-button role="unlock" @click="unlock()" v-if="locked == 1" :disabled="!$hasPermission('mps:estMade:lock-budget')"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <vxe-table
          v-if="tableShow"
          :loading="dataListLoading"
          ref="xTable"
          :height="tableHeight"
          border
          auto-resize
          show-overflow
          keep-source
          :row-config="{isHover: true, isCurrent: true, keyField: 'id'}"
          :column-config="{resizable: true}"
          :tree-config="treeConfig"
          :edit-config="{enabled: locked < 1, trigger: 'click', mode: 'cell', showStatus: true, beforeEditMethod: beforeEditMethod}"
          :data="tableData"
          size="mini"
          @edit-closed="editClosedEvent"
          :footer-method="footerMethod"
          :show-footer="showFooter"
          :cell-class-name="cellClassName"
          footer-cell-class-name="mytable-footer-summary">
        <vxe-column field="code" tree-node width="200" title="编号" fixed="left"></vxe-column>
        <vxe-column field="name" min-width="250" title="名称" fixed="left"></vxe-column>
        <vxe-column field="unitName" width="100" title="单位" align="center"></vxe-column>
        <vxe-column field="autoCalculateIds" width="100" title="计算方式" align="center" :formatter="autoCalculateFormatter"></vxe-column>
        <!-- 目前缺少相关小数位精度的参数，因此暂不做控制，用户填多少就显示多少 -->
        <vxe-column field="budgetNum1" width="150" title="预算数量1" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
        <vxe-column field="budgetNum2" width="150" title="预算数量2" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
        <vxe-column field="budgetMoney" width="150" title="预算金额（万元）" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
      </vxe-table>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getRootData" url="/mps/estMade/import" thumbnail="import_drawing_budget.jpg"></excel-import>
    </div>
  </el-card>
</template>

<script>
// 施工图预算
import mixinViewModule from '@/mixins/view-module'
import XEUtils from 'xe-utils'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
      },
      dataForm: {
        prjId: "", // 项目ID
        importFlag: 2
      },
      treeConfig: {
        transform: false,
        rowField: 'id',
        parentField: 'pid',
        // line: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadChildren,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      tableData: [],
      tableShow: true,
      dataListLoading: false,
      tableHeight: 600,
      locked: 1,
      showFooter: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  methods: {
    // 项目切换事件
    projectChange (project) {
      this.locked = project.budgetLock
      this.getRootData()
    },

    // 导入（不自动创建项目节，仅更新数量和金额）
    importMadeHandle () {
      if (this.locked == 0) {
        this.$confirm('仅导入施工图设计预算的数量和金额，是否继续？', '操作确认', {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.importHandle()
        }).catch()
      } else {
        return this.$message.error('概预算已锁定，不允许导入。')
      }
    },

    // 异步加载子节点
    loadChildren ({row}) {
      return new Promise(resolve => {
        this.$http.get('/mps/estMade/children',
          {
            params: {
              pid: row.id,
              prjId: this.dataForm.prjId
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data)
          if (res.data && res.data.length > 1) {
            this.showFooter = true
          } else {
            this.showFooter = false
          }
        }).catch(() => {
          // do nothing
        })
      })
    },

    // 加载根节点
    getRootData () {
      this.tableData = []
      // 重新创建table，清空缓存
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      if (this.dataForm.prjId) {
        this.dataListLoading = true
        this.$http.get('/mps/estMade/children',
          {
            params: {
              pid: 0,
              prjId: this.dataForm.prjId
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
          if (this.tableData && this.tableData.length > 1) {
            this.showFooter = true
          } else {
            this.showFooter = false
          }
          this.dataListLoading = false
        }).catch(() => {
          // 查询出错
          return this.$message.error('出错了')
        })
      }
    },

    lock() {
      this.$confirm(`您是否要锁定施工图预算？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/estMade/lockBudget/${this.dataForm.prjId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.locked = 1
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          this.$refs.projectSelect.updateData(this.dataForm.prjId, "budgetLock", 1)
          this.getDataList()
          this.$message({
            message: '已锁定',
            type: 'warning',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).finally(() => {
          loading.close()
        })
      })
    },
    unlock() {
      this.$confirm(`您是否要解锁施工图预算？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/estMade/unlockBudget/${this.dataForm.prjId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          this.$refs.projectSelect.updateData(this.dataForm.prjId, "budgetLock", 0)
          this.locked = 0
          this.$message({
            message: '已解锁',
            type: 'warning',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).finally(() => {
          loading.close()
        })
      })
    },
    autoCalculateFormatter({cellValue}) {
      if (cellValue) {
        return '自动计算'
      } else {
        return '手动填写'
      }
    },

    // 单元格退出编辑事件
    editClosedEvent ({row, column}) {
      const $table = this.$refs.xTable
      const field = column.property
      //const cellValue = row[field]
      // 判断单元格值是否被修改
      if ($table.isUpdateByRow(row,field)) {
        if (field === 'budgetNum1' || field === 'budgetNum2') {
          this.$http.put(`/mps/estMade/data/${field}`, row).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                // do nothing
              }
            })
            // 局部更新单元格为已保存状态
            this.refreshMyRow($table, row, field)
          }).catch(() => {
            // 查询出错
            return this.$message.error('出错了')
          })
        } else if (field === 'budgetMoney') {
          this.$http.put(`/mps/estMade/data/${field}`, row).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                // do nothing
              }
            })
            // 更新其他受影响的自动计算的行
            let list = res.data
            if (list && list.length > 0) {
              for (let item of list) {
                let targetRow = $table.getRowById(item.id)
                targetRow.budgetMoney = item.budgetMoney
              }
            }
          }).catch(() => {
            // 查询出错
            return this.$message.error('出错了')
          })
          // 级联计算上级节点的概算金额
          XEUtils.eachTree($table.getTableData().fullData, (row,index,items,path,parent,nodes) => {
            let children = row.children
            if (children && children.length) {
              // 合计子节点
              Object.assign(row, this.handleSummary(children, field))
              this.refreshMyRow($table, row, field)
            } else {
              if (index === items.length - 1) {
                // 全量汇总
                for (let len = nodes.length - 2; len >= 0; len--) {
                  Object.assign(nodes[len], this.handleSummary(nodes[len].children, field))
                  this.refreshMyRow($table, nodes[len], field)
                }
              }
            }
          },{
            children: 'children',
            accordion: false, // 一层只允许展开一个节点
            expandAll: false // 默认是否全部展开
          })
          this.refreshMyRow($table, row, field)
        }

        // 必须在修改数据后执行合计行的更新，否则将导致合计行的数据不准确
        $table.updateFooter()
      }
    },

    // 递归汇总各级节点的相关金额
    handleSummary (children, fieldName) {
      if (fieldName === 'budgetMoney') {
        return {
          //budgetMoney: Math.floor(XEUtils.sum(children, 'budgetMoney'))
          budgetMoney: XEUtils.sum(children, 'budgetMoney')
        }
      }
    },

    /**
     * 局部加载行数据并恢复到初始状态 （vxe table reloadRow方法对树形表格会出现bug，行数据无法恢复到初始状态）
     * @param table vxe table组件对象
     * @param row 行对象(最新修改的数据)
     * @param field 字段名
     */
     refreshMyRow($table, row, field) {
      if ($table && row && field) {
        const {keepSource,tableSourceData,tableData} = $table
        if (keepSource) { // vxe table开启了 keep-source
          const newValue = XEUtils.get(row, field)
          let resultData = XEUtils.findTree(tableSourceData,item => item.id === row.id,{children: 'children'})
          if (resultData) {
            XEUtils.set(row, field, newValue)
            XEUtils.set(resultData.item, field, newValue)
          }
          $table.tableData = tableData.slice(0)
        }
      }
    },
    
    // 合计行
    // 缺陷：该方法在点击输入框时会被频繁调用，但是修改了树形结构叶子节点的某数值，并不能保证此方法的根节点汇总结果是正确的，
    // 因此，在必要的环节需主动调用 $table.updateFooter()
    footerMethod({columns, data }) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return '合计'
          }
          if (['budgetMoney'].includes(column.field)) {
            return this.getFooterTotal(data, 'budgetMoney')
          }
          return null
        }),
      ]
      return footerData
    },
    // 计算底部合计行中的相关金额
    getFooterTotal(data, columnName) {
      // 没有对data进行递归，仅计算了根节点（data中有children属性）
      if (columnName == 'budgetMoney') {
        let total = 0
        for (let i = 0; i < data.length; i++) {
          // 排除掉自动计算的（自动计算的暂时理解为对其他行的小计），防止重复累加
          if (!data[i].autoCalculateIds) {
            total = XEUtils.add(total, data[i].budgetMoney)
          }
        }
        //return this.numberFormatter(total)
        // 当前模块目前缺少小数位精度的参数，暂不做控制，用户填写多少就显示多少
        return total
      }
    },
    // 小数位精度处理
    numberFormatter (value) {
      if (!value) return '0.00'
      value = value - 0
      return XEUtils.commafy(value, { digits: 2 })
    },

    // 单元格是否允许编辑
    cellCanEdit(row, column){
      // 金额只允许叶子节点编辑，数量允许所有节点编辑
      if (!this.$hasPermission('mps:estMade:budgetUpdate') || this.locked >= 1) {
        return false
      } else if (column.property === 'budgetNum1' || column.property === 'budgetNum2') {
          return true
      } else if ((column.property === 'budgetMoney') && row.isLeaf) {
        return true
      }
      return false
    },

    //  树节点是否可编辑
    beforeEditMethod({row, rowIndex, column, columnIndex}) {
      return this.cellCanEdit(row, column)
    },

    cellClassName({row, column}) {
      if (this.cellCanEdit(row, column)) {
        return 'cell-underline'
      } else {
        return ''
      }
    },
  }
}
</script>
<style>
  .mytable-footer-summary {
    background-color: #D4E6F1;
    font-weight: bold;
  }
</style>